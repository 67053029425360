.font-setting {
	.mce-edit-area {
		display: none;
	}

	.text-container {
		margin-top: 10px;
		padding: 15px;
		text-align: left;
		background-color: #efefef;
		border-radius: 8px;
	}
}