.steps {
  display: flex;
  flex-direction: row;
  text-align: center;
  position: relative;
  margin-bottom: 10px;
}
.steps:after {
  display: block;
  content: '';
  position: absolute;
  bottom: -4px;
  width: 100%;
  height: 4px;
  background-color: $light-gray;
}
.step-item {
  flex-grow: 1;
  position: relative;
  color: $gray;
}
.step-item span {
  padding: 4px 0;
  font-size: 0.9em;
  display: block;
}
.step-item:after {
  display: block;
  content: '';
  position: absolute;
  bottom: -4px;
  width: 100%;
  height: 4px;
  background-color: $rise-green;
}
.step-item:before {
  display: block;
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -8px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: $white;
  border: 3px solid $light-gray;
  z-index: 100;
}

.step-item.active:before {
  border: 3px solid $rise-green;
}

.step-item.complete:before {
  border: 3px solid $rise-green;
  background-color: $rise-green;
}

.step-item.complete:first-child:after {
  width: 50%;
  right: 0;
}
.step-item.complete:last-child:after {
  width: 50%;
  left: 0;
}
.step-item.active:first-child:after {
  width: 0;
}

.step-item.complete {
  color: $rise-green;
  z-index: 100;
}
.step-item.active {
  color: $rise-green;
  z-index: 100;
}
.step-item.active:after {
  width: 50%;
}

/* ==========================================================================
   Small devices (tablets, 768px and up)
   ========================================================================== */

 
@media (min-width: 768px) {
  .step-item span {
    padding: 8px;
    display: block;
    font-size: 1em;
  }
}



.progress-indicator {
  background-color: red;
  width: 100%;
  text-align: center;
  padding: 0;
  display: inline-block;
  margin: 30px 0 0 0;
  cursor: default;
  .indicator {
    width: 15px;
    height: 15px;
    border: 4px solid #dddddd;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    .tag {
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      color: #dddddd;
      cursor: default;
    }
    &.complete {
      border-color: #4ab767;
      .tag {
        color: #4ab767;
        &:before {
          content: "\f00c";
          margin-right: 4px;
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          text-decoration: inherit;
          color: #4ab767;
        }
      }
    }
    &.active {
      border-color: #4ab767;
      .tag {
        color: #4ab767;
        &:before {
          content: "";
          margin-right: 4px;
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          text-decoration: inherit;
          color: #4ab767;
        }
      }
    }
  }
  .progress-bar-container {
    width: 17%;
    height: 2px;
    display: inline-block;
    background-color: #dddddd;
    position: relative;
    top: -6px;
    &:last-of-type {
      display: none;
    }
    .progress-bar {
      width: 0;
      height: 100%;
    }
    &.complete {
      background-color: #4ab767;
    }
  }
}