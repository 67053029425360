/* ==========================================================================
   Font Picker
   ========================================================================== */
.font-picker .modal {
  margin-top: 40px;
}

.font-picker-text {
  /* Display & Box Model */
  padding: 15px;

  /* Visual */
  text-align: left;
  background-color: #efefef;
  border-radius: 8px;
}

.font-select-btn {
  width: 149px;
  height: 30px;
  position: relative;

  .bfh-selectbox-option{
    position: absolute;
    top:4.5px;
    left:3.5px;
  }

  .selectbox-caret{
    float: right;
  }
}
