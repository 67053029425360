.filters {
    margin-top: 20px;
    margin-right: 20px;
    text-align: center;
    .btn-default {
        width: 140px !important;
        margin-top: 7px;
        border: 0;
        border-bottom: 3px solid #eaeaea;
        border-radius: 0;
        background-color: #FCFCFC;
        float: none;

    }
    .btn-default.active {
        color: #3b9351;
        border-bottom: 3px solid #52cc70;
        background-color: #FCFCFC;
        box-shadow: none;
    }
    .btn-default:active,
    .btn-default:focus {
        outline: 0;
        box-shadow: none;
    }
    .btn-default:hover {
        background-color: #eee;
    }
    &.container{
        text-align: center;
        padding-left: 0;
        margin-bottom: 8px;
        width: 1157px;
    }
}

.mobile-filters {
    display: none;
    .btn {
        width: 100% !important;
        margin-top: 10px;
    }
}

.store-selector {
    .btn-default {
        width: 100% !important;
        padding: 20px 0;
    }
    .btn-default:active {
        box-shadow: none;
    }
    .btn-default.active {
        color: #3b9351;
        border: 1px solid #52cc70;
        box-shadow: none;
    }
    .btn-default.active span::before {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        content: '✔';
        background-repeat: no-repeat;
    }
}


/*MOBILE BREAKPOINTS */

@media screen and (max-width: $screen-sm) {
    .filters {
        display: none;
    }
    .mobile-filters {
        display: block;
    }
}

/*LINE BREAK FIX*/

@media only screen and (min-width: $screen-sm-min) and (max-width: $screen-lg) {
    .filters .btn-default {
        width: 118px !important;
        padding: 6px 0;
    }
}

/*EXPANDED THE PRODUCT CONTAINER ON TABLET AND BELOW */

@media only screen and (max-width: $screen-sm) {
    .product-container {
        width: 100%;
    }
}
