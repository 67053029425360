@use "@angular/material" as mat;
/* Variables
   ========================================================================== */

// Colors
$rise-green: #4ab767;
// $rise-blue: #0081BA;
$start-color: #1f71a2;
$stop-color: #045888;
$danger: #d9534f;
$google-red: #df4a32;
$rise-premium: #dbf0e0;
$rise-blue: #107dda;

// Tones
$white: #fff;
$lighter-gray: #f2f2f2;
$light-gray: #e6e6e6;
$mid-gray: #999999;
$gray: #555;
$dark-gray: #4d4d4d;

$border-color: #ccc;
$light-border-color: #DDDDDD;
$blue-border-color: $rise-blue;
$border: 1px solid $border-color;

// Spacing
$title-margin: 6px;

// New Style
$madero-black: #020620;
$madero-gray: #999999;
$madero-light-blue: $rise-blue;
$madero-green: #45b764;
$madero-green-active: #10792D;
$madero-blue-active: #4D90FE;
$madero-green-disabled: #a2dbb2;

// Text
$default-text: $madero-black;
$text-inverse: $white;

// Padding
$pad-sm: .25rem; //4px
$pad-md: .5rem; //8px
$pad-lg: 1rem; //16px

// Shadows
$bottom-shadow: 0 .25rem .25rem $madero-gray;
$bottom-shadow-small: 0 0 2px $madero-gray;
$vignette-shadow: inset 0 0 3px $madero-gray;

// Template Cards
$card-border-radius: 1rem;
$card-image-radius: .5rem;
$card-color: $white;
$card-margin: $pad-md;

//Transitions
$transition-default: .25s ease all;


// Generated using https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
// 700 is $rise-blue
$rise-blue-palette: (
  50: #e3f3fe,
  100: #bce0fd,
  200: #91cefd,
  300: #64bafc,
  400: #40abfc,
  500: #199cfb,
  600: #168eed,
  700: #107cda,
  800: #0c6bc8,
  900: #034ca9,
  contrast: (
    50: #020620,
    100: #020620,
    200: #020620,
    300: #020620,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff
  )
);

// Generated using https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
// 400 is $rise-green
$rise-green-palette: (
  50: #e5f5e9,
  100: #c1e5c8,
  200: #98d4a5,
  300: #6dc481,
  400: #4ab767,
  500: #1eaa4c,
  600: #139b43,
  700: #008a38,
  800: #00792d,
  900: #005a1a,
  contrast: (
    50: #020620,
    100: #020620,
    200: #020620,
    300: #020620,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff
  )
);

$rise-primary: mat.define-palette($rise-green-palette, 400);
$rise-accent: mat.define-palette($rise-blue-palette, 700);
