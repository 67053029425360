.toolbar {
  overflow: hidden;
  text-align: right;
  margin-top: 10px;
}

.icon-nav {
  display: inline-block;
  color: #555;
  line-height: 32px;
  border-right: 1px solid $light-border-color;
  margin-right: 20px;
  padding-right: 10px;
  label {
    @extend .btn;
  }
  .btn-link {
    padding: 0;
    border-radius: 3px;
    min-width: 32px;
    height: 32px;
    vertical-align: top;
    color: $dark-gray;
    &:hover {
      background:$light-gray;
    }
    &:focus {
      outline:0;
    }
    .fa {
      font-size: 20px;
      line-height: 32px;
      &:before {
        color: $dark-gray;
      }
    }
    .fa+.fa {
      margin-left: -4px;
      margin-top: 6px;
      font-size: 12px;
      vertical-align: top;
    }
  }
}

.selected-count {
    display: inline-block;
    line-height: 32px;
    border-right: 1px solid #DDD;
    margin-right: 10px;
    padding-right: 16px;
}

.custom-icon {
  display: inline-block;
  background-size: 100%;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
