/* ==========================================================================
   Alignment
   ========================================================================== */
/* Style the component */
.alignment {
  .dropdown-menu {
    /* Display & Box Model */
    min-width: 145px;
    padding: 5px;
  }
}

.btn-alignment {
  /* Display & Box Model */
  min-width: 50px;
}