.colorpicker {
  .dropdown-menu {
    &:before,
    &:after {
      width: 10px !important;
    }
  }
  .close-colorpicker {
    display: none !important;
  }
}
