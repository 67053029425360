.data-table-component {
  .data-table-label {
    width: 100px;
  }

  .row-hover {
    &:hover {
      background-color: $gray-lightest;
    }
  }
}

.provider-connected-file-info {
  .file-icon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
}

.btn {
  .refresh-icon {
    fill: $madero-green;
  }

  &:active {
    .refresh-icon {
      fill: $dark-gray;
    }
  }
}

