.madero-style {
  .input-group {
    .form-control {
      border-color: $madero-gray;
      height: 40px;

      &:focus {
        border: 1px solid $madero-light-blue;
        box-shadow: none;
      }

      &:not(:first-child):not(:last-child) {
        border-left: none;
        border-right: none;
      }
      &:first-child {
        border-right: none;
      }
      &:last-child {
        border-left: none;
      }
    }

    timepicker {
      .form-control,
      .form-control:first-child,
      .form-control:last-child {
        border-radius: 5px;
        border-left: 1px solid $madero-gray;
        border-right: 1px solid $madero-gray;
      }

      a.btn {
        min-height: 0;
      }
    }

    &:focus-within .input-group-addon {
      border-color: $madero-light-blue;
      box-shadow: none;
    }
  }
}

.custom-addon,
.checkbox-addon,
.storage-group {
  .input-group-addon {
    background: none;
    padding: 0 10px 0 10px;
    border: 0;
    vertical-align: top;
  }
  .form-control {
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
}

.input-group-addon {
  background-color: $gray-lightest;
  border-radius: $border-radius-base2;
  border-color: $madero-gray;
  color: $madero-black;

  &:first-child {
    border-right: none !important;
  }

  &:last-child {
    border-left: none !important;
  }

  svg {
    // filter: invert(60%) sepia(83%) saturate(0%) hue-rotate(157deg) brightness(87%) contrast(87%);
    fill: $madero-gray;
  }
}

.form-inline .form-control-static {
  display:inline-block;
}

.form-control-static {
  vertical-align: middle;
  border-radius: 4px;
  padding: 6px 0;
}

.control-label {
  line-height: 22px;
  font-weight: 600;
  color: #444;
}

textarea.form-control {
  &.resize-vertical {
    resize: vertical;
  }
}