// COLORS
$white: hsla(0, 0%, 100%, 1);
$darkgray: hsla(0, 0%, 24%, 1);
$base-gray: hsla(203, 15.8537%, 32.1569%, 1.0000);
$gray-saturate-85: saturate($base-gray, 85%);
$button-blue: lighten($gray-saturate-85, 7%);

// TRANSITION SPEEDS
$transition-fast: 0.15s;

// ON OFF SWITCH
$switch-height: 22px;
$switch-width: 55px;
$switch-group-height: $switch-height + 2;
$toggle-group-height: $switch-height + 5;

// MIXINS
@mixin box-sizing($sizing: border-box){
    -moz-box-sizing: $sizing;
    -webkit-box-sizing: $sizing;
    box-sizing: $sizing;
}

@mixin transition($attr,$time,$ease){
    -webkit-transition: $attr $time $ease;
    -o-transition: $attr $time $ease;
    -moz-transition: $attr $time $ease;
    transition: $attr $time $ease;
}

@mixin border-radius($radius: 4px){
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
}

/* ON/OFF Switch */
.onoffswitch {
    position: absolute;
    width: $switch-width;
    font-size: 80%;
    left: 3px;
    top: 0;

    .onoffswitch-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 1px solid darken($white, 10%);
        @include border-radius($switch-height - 2);
        margin: 0;
    }

    .onoffswitch-inner {
        width: 200%;
        margin-left: -100%;
        @include transition(margin, $transition-fast, ease-in-out);

        &:before,
        &:after {
            float: left;
            width: 50%;
            height: $switch-group-height;
            padding: 0;
            line-height: $switch-group-height;
            font-size: 100%;
            color: $white;
            font-weight: normal;
            @include box-sizing(border-box);
        }

        &:before {
            content: "ON";
            padding-left: 10px;
            background-color: $button-blue;
            color: $white;
            padding-right: 29px;
        }

        &:after {
            content: "OFF";
            padding-right: 9px;
            background-color: darken($white, 2%);
            color: $darkgray;
            text-align: right;
        }
    }

    .onoffswitch-switch {
        width: $switch-height;
        height: $switch-height;
        margin: 0;
        background: $white;
        box-shadow: 0 0 3px rgba(0,0,0,.3);
        @include border-radius(50%);
        position: absolute;
        top: 2px;
        bottom: 0;
        right: $switch-height + 13;
        @include transition(right, $transition-fast, ease-in-out);
    }
}

.toggle-group {
    position: relative;
    height: $toggle-group-height;
    overflow: hidden;

    input[type=checkbox] {
        position: relative;
        left: -20px;
        top: 0;
        width: 76px;
        height: 27px;
        margin: 0;
        padding: 0;
        vertical-align: top;
        z-index: 100;
        opacity: 0;

        &:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-inner {
            margin-left: 0;
        }

        &:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
            right: 1px;
            box-shadow: 0 0 3px rgba(0,0,0,.5);
        }

        &:focus  ~ .onoffswitch {
            outline: thin dotted #333;
            outline: 0;
        }

         &:disabled  ~ .onoffswitch .onoffswitch-inner:after,
         &:disabled  ~ .onoffswitch .onoffswitch-inner:before,
         &:disabled  ~.onoffswitch .onoffswitch-label .onoffswitch-switch {
            background-color: #DDD;
            color: #DDD;
        }
    }

    label {
        line-height: 27px;
    }

}

/* ==== Accessibility ==== */
.aural {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;

    &:focus {
        clip: rect(0, 0, 0, 0);
        font-size: 1em;
        height: auto;
        outline: thin dotted;
        position: static !important;
        width: auto;
        overflow: visible;

    }
}
