ul.tree,
ul.tree ul {
    margin:0;
    padding:0;
}
ul.tree ul {
    margin-left:10px; /* indentation */
    position:relative;
}
ul.tree ul:before {
    content:"";
    display:block;
    width:0;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    border-left:1px solid #d4d4d4;
}
ul.tree li {
    margin:0;
    padding:0 15px; /* indentation + 2 */
    line-height:30px; /* default list item `line-height` */
    position:relative;
    a {
      color: #444;
      font-weight: 600;
      padding: 4px;
      border-radius: 2px;
      .fa:before {
      	color: #d4d4d4;
      	margin-right: 5px;
      	font-size: 24px;
      	vertical-align: middle;
      }
      &:hover {
        background: lighten($rise-green, 40%);
      }
    }
}
ul.tree ul li:before {
    content:"";
    display:block;
    width:10px; /* same with indentation */
    height:0;
    border-top:1px solid #d4d4d4;
    position:absolute;
    top:15px;
    left:0;
}
ul.tree ul li:last-child:before {
    background:white; /* same with body background */
    height:auto;
    top:15px; /* (line-height/2) */
    bottom:0;
}