.madero-style {

  .has-error {
    tags-input {
      .tags, .tags.focused {
        border: 1px solid $danger;
        box-shadow: none;
      }
    }
  }

  .email-tags {
    .tags {
      border-color: $madero-gray;
      box-shadow: none;

      .input {
        min-height: 40px;
        margin: 0px 5px;
      }

      &.focused {
        border: 1px solid $rise-blue;
      }

      .tag-item {
        background: $rise-blue;
        border: 1px solid $rise-blue;
      }

      .invalid-tag {
        color: $danger;
      }
    }
  }
}

tag-input {
  &.email-tags .ng2-tag-input {
    background-color: #fff;
    border: 1px solid $madero-gray;
    border-radius: 4px;
    box-shadow: none;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    padding: 4px 12px 4px 7px;

    .ng2-tags-container {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .has-error & {
      border-color: $danger;
    }
  }

  &.email-tags .ng2-tag-input.ng2-tag-input--focused {
    border: 1px solid $rise-blue;
    box-shadow: none;

    .has-error & {
      border-color: $danger;
      box-shadow: none;
    }
  }

  &.email-tags .ng2-tag-input tag {
    background: #007bc8;
    border: 1px solid #357ebd;
    border-radius: 4px;
    color: #fff;
    font: revert;
    letter-spacing: revert;
    height: 26px;
    line-height: 25px;
    margin: 2px;
  }

  &.email-tags .ng2-tag-input tag delete-icon {
    path {
      fill: #fff;
    }

    &:hover {
      transform: none;
    }

    svg {
      height: 25px;
    }
  }

  &.email-tags .ng2-tag-input input {
    font-family: revert;
    margin: 0;
    padding: 0 0 0 5px;
    height: 30px;
    width: 298px;

    .has-error & {
      color: #c0392b;
    }
  }

}
