.btn-lg {
    @include button-size(10px, 16px, 16px, 1.33, 6px, 400);
}

.btn-hg,
.btn-group-hg > .btn {
    @include button-size(14px, 24px, 18px, 1.33, 4px, 400);
}

.btn-default {
    @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border, $btn-default-border-disabled, #fff, #d5d5d5);
    .fa:before {
        color: $dark-gray;
    }
}

.btn-inverse {
    @include button-variant($white, $gray-darker, $gray, $btn-default-border-disabled, $rise-green, lighten($gray-darker, 5%));
}

.btn-primary {
    @include button-variant($white, $rise-green, $rise-green, $btn-default-border-disabled, darken($rise-green, 10%), lighten($rise-green, 5%));
}

.btn-provider-auth {
  @extend .flex-row;

  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
  border: 0;

  & img {
    width: 44px;
    padding: 9px;
    margin-right: 10px;
  }
}

.btn-google-auth {
    @include button-variant($white, #4285F4, $btn-default-bg, $btn-default-border-disabled, darken($google-red, 10%), lighten($google-red, 5%));
    & img {
        background-color: white;
    }
}

.btn-microsoft-auth {
  &.dark {
    @include button-variant(#FFFFFF, #2F2F2F, #2F2F2F);
  }
  &.light {
    border: 1px solid;

    @include button-variant(#5E5E5E, #FFFFFF, #8C8C8C);
  }
}

.btn-toggle-blue-on {
    @include button-variant($white, $btn-primary-bg, $btn-primary-bg, $btn-default-border-disabled, #fff, #d5d5d5);
    .fa:before {
        color: $white;
    }
}

.btn-toggle-blue-off {
    @include button-variant($btn-primary-bg, $white, $btn-primary-bg, $btn-default-border-disabled, #fff, #d5d5d5);
    .fa:before {
        color: $btn-primary-bg;
    }
}

.btn-white {
    @include button-variant(darken($rise-green, 10%), $white, $white, $btn-default-border-disabled, darken($white, 10%), lighten($white, 5%));
}

.btn-toggle {
    // display: inline;
    display: none;
    &.active {
        display: inline-block !important;
        color: #333;
        background-color: #e8e8e8;
        border-color: #CCC;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.button-row {
  overflow: hidden;
  margin: 40px 0 40px;
}

.button-row, .modal-footer.button-row {
    @media (max-width: $screen-sm) {
        .btn {
            display: block;
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.modal-footer.button-row {
    @extend .my-0;

    @media (max-width: $screen-sm) {
        .btn {
          margin-left: 0px;
        }
    }
}

.button-flex-row {
    display: flex;
    flex-direction: column;
    width: 100%;

    .btn {
        width: 100%;
    }

    @media(min-width: $screen-xs) {
        flex-direction: row;
        gap: 1rem;
    }
}

.madero-style {
    .btn-toggle-blue-on {
      background-color: $madero-light-blue;
      border-color: $madero-light-blue;

      svg {
        fill: $white;
        width: 15px;
        height: 15px;
        vertical-align: text-top;
        margin-right: 7px;
      }
    }

    .btn-toggle-blue-off {
      color: $madero-black;
      background-color: $white;
      border-color: $madero-light-blue;

      &:hover, &:active, &:focus {
        background-color: $white;
      }
    }

    .btn-toggle {
      color: $madero-green;
      background: white;
      border-color: $madero-green;

        &.active {
          color: white;
          background-color: $madero-green;
          border-color: $madero-green;

          svg {
          fill: $white;
          padding-left: 7px;
          }
        }
    }
  }
