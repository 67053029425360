.madero-style {

  #plan-banner {
    .btn {
      min-height: 0;
    }
  }

  select.form-control.has-placeholder {
    &.ng-empty {
      color: $madero-gray;
    }
  }

  .btn-default-icon {
    @extend .btn-default;

    &:active,
    &:focus,
    &:hover {
      background: $white;
      border-color: $madero-green;
    }

    svg {
      margin: 0;
      vertical-align: middle;
      path {
        fill: $madero-green;
      }
    }
  }

  // dropdown selector button
  .btn-select {
    text-align: left;
    font-weight: normal;

    .text-muted {
      color: $madero-gray;
    }
  }

  input:disabled+label {
    color: $madero-gray;
    cursor: not-allowed;
  }

  .btn-dropdown {
    // duplicate "dropdown-menu > ul > li > a" styles
    color: $dark-gray;
    padding: 10px;
    margin: 0;
    background-color: white;
    border: 0;
    width: 100%;
    text-align: left;

    &:disabled {
      color: $madero-gray;
      cursor: not-allowed;
    }

    &:hover:enabled,
    &:focus:enabled {
      // duplicate "dropdown-menu > ul > li > a:hover" styles
      background-color: rgba(150, 150, 150, 0.1);
      color: $rise-blue;
      // duplicate <a> styles
      text-decoration: underline;
      text-align: left;
      width: 100%;
    }
  }

  .text-danger {
    color: $danger;
  }

  .text-gray {
    color: $mid-gray;
  }

  .bg-lighter-gray {
    background-color: $lighter-gray;
  }

  last-modified .text-muted {
    @extend .text-gray;
  }

  .border-round {
    border: 1px solid $madero-gray;
    border-radius: 4px;
  }

  .border-bottom {
    border-bottom: 1px solid $madero-gray;
  }

  .border-top {
    border-top: 1px solid $madero-gray;
  }

  .border-container {
    @extend .border-round;

    &.scrollable-list {
      min-height: auto;

      .list-group-item {
        @extend .border-top;

        border-radius: 0px;

        &:first-child {
          border-top: 0;
        }

        &:last-child {
          @extend .border-bottom;

          margin-bottom: -1px !important;
        }
      }

      &.horizontal-scroll {
        overflow-x: auto;
      }

      .table>tbody>tr>td, .table>thead>tr>th {
        @extend .u_padding-md-vertical;
        @extend .u_padding-sm-horizontal;
        @extend .u_nowrap;

        .madero-checkbox {
          @extend .my-0;
        }
      }

      .table.dense>tbody>tr>td, .table.dense>thead>tr>th {
        @extend .u_padding-sm-vertical;
      }

      .table>thead>tr>th {
        font-weight: bold;
        border-bottom: 0;
      }

      .table>tbody>tr {
        @extend .border-top;

        &>td, &.borderless {
          border-top: none;
        }
      }
    }

    &.light-border {
      border-color: $light-border-color;
    }
  }

  .btn-danger {
    background: $danger;
    border-color: $danger;
  }

  .btn.btn-auto {
    font-weight: normal;
    min-height: auto;
  }

  .btn-toolbar {
    min-width: 160px;
    margin-left: auto;
  }

  .btn-toolbar-wide {
    min-width: 200px;
    margin-left: auto;
  }

  .button-toolbar {
    & > * {
      @extend .mr-3;

      &:last-child{
        @extend .mr-0;
      }
    }
  }

  .button-row {
    & > * {
      @extend .mr-md-3;

      &:last-child{
        @extend .mr-0;
      }
    }
  }

  @media (max-width: 768px) {
    .layout-md-folded {
      display: block;
    }
    .button-toolbar-md-folded {
      display: block;

      & > * > * {
        margin-top: 1rem !important;
      }

      & > *:first-child > * {
        margin-top: 0 !important;
      }

      .button-toolbar {
        display: block;
      }

      .btn-toolbar, .btn-toolbar-wide {
        width: 100%;
      }
    }
  }

  .streamline-component-icon {
    display: inline-block;
    margin-right: .5rem;
    position: relative;
    top: 3px;
  }

  .btn {
    .streamline-component-icon {
      top: 1px;
    }
  }

  .rv-sortable-item {
    &.disabled {
      background-color: $lighter-gray;
    }

    .rv-sortable-handle {
      cursor: move;
      cursor: -webkit-grabbing;

      streamline-icon {
        display: block;

        svg {
          fill: $mid-gray;
        }
      }
    }
  }

  .scrollable-list {
    .rv-sortable-handle {
      @extend .pr-1;
    }
  }

  .progress {
    height: 10px;
    box-shadow: none;
    background-color: transparent;
    border: 1px solid $madero-gray;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .progress-bar {
    width: 50%;
    background: $madero-green;
    animation: none;
    height: 100%;
    box-shadow: none;
  }

  .fields-preview-panels {
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-flow: column-reverse;

    @media (min-width: $screen-md) {
      display: flex;
      flex-flow: row;
      height: calc(100vh - var(--body-height));
    }

    .preview-container {
      width: 100%;
      overflow: auto;
      padding-right: 8px;

      @media (min-width: $screen-md) {
        @extend .ml-lg-5;

        width: 100%;
        height: 100%;
      }

      &::-webkit-scrollbar {
        width: var(--scrollbar-size);
      }
      
      &::-webkit-scrollbar-track {
        background: #cccccc;
        border-radius: var(--scrollbar-size);
      }
      
      &::-webkit-scrollbar-thumb {
        border-radius: var(--scrollbar-size);
        background: gray;
      }
    }

    .fields-container {
      --sidebar-width: 450px;

      height: 100%;

      @media (min-width: $screen-md) {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: var(--sidebar-width);
        width: var(--sidebar-width);
      }
    }
  }

  .preview-embed {
    position: relative;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
    }
  }

  &.tooltip {
    opacity: 1;
    white-space: normal;

    // This overrides the `pointer-events: none` style that ngx-bootstrap dynamically adds to the page,
    // which suppresses all mouse interactions with elements contained within a tooltip.
    pointer-events: all !important;

    .scrollable-list {
      min-height: 150px;
      max-height: 150px;
    }

    &.basic-tooltip,
    &.thumbnail-tooltip {
      opacity: 1;
      z-index: 9999;
      .tooltip-arrow {
        display: none;
      }
      .tooltip-inner {
        padding: 10px;
        border: 0px;
        box-shadow: 0px 0px 5px 0px rgba(2, 6, 32, 0.5);
        opacity: 1;
        line-height: 1.5;
        background-color: #fff;
        overflow: hidden;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
    }

    &.basic-tooltip {
      .tooltip-inner {
        text-align: left;
        max-width: 374px;
        width: 374px;

        @media (max-width: $screen-sm) {
          width: 350px;
          max-width: 350px;
        }
      }
    }

    &.tooltip-share-options {
      margin-left: -106px;

      .tooltip-inner {
        text-align: left;
        padding: 10px;
        border: 0px;
        box-shadow: 0px 0px 5px 0px rgba(2, 6, 32, 0.5);
        opacity: 1;
        max-width: 374px;
        width: 374px;

        .close {
          height: 10px;
          width: 10px;

          svg {
            fill: $madero-gray;
          }
        }

        .input-group-btn {
          width: 1%;
        }
      }

      &.bottom {
        margin-top: -8px;
      }

      .tooltip-arrow {
        display: none;
      }

      &.tooltip-sm {
        .tooltip-inner {
          width: 303px;
        }
      }
    }

    &.tooltip-schedule-selector {
      @extend .tooltip-share-options;

      .tooltip-inner {
        max-width: 360px;
        width: 360px;
        padding: 15px;
        font-size: 14px;

        hr {
          border-top: 1px solid $madero-gray;
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }

      &.bottom {
        margin-top: -9px;
      }
    }

    &.tooltip-schedule-selector {
      @extend .tooltip-share-options;

      .tooltip-inner {
        max-width: 360px;
        width: 360px;
        padding: 15px;
        font-size: 14px;

        hr {
          border-top: 1px solid $madero-gray;
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }

      &.bottom {
        margin-top: -9px;
      }
    }
  }

  .popover {

    border-radius: 0;
    box-shadow: none;

    .popover-content div {
      @extend .u_ellipsis-md;
    }

    .popover-arrow {
      display: none;
    }

    &.timepicker {
      border-radius: 5px;
      margin-top: 3px;
      padding: 5px 0;
      width: 200px;
    }

    &.upgrade-popover {
      border-radius: 6px;
      margin-top: -4px;
      background-color: $rise-blue;
      border-color: $rise-blue;
      color: $white;

      &.right {
        .arrow {
          left: -9px;
          border-right-color: $rise-blue;

          &::after {
            border-right-color: $rise-blue;
          }
        }
      }

      .popover-arrow {
        display: block;
      }

      .popover-content a {
        color: $white;
      }
    }
  }

  &.action-sheet {
    &.tooltip-share-options {
      padding: 20px;
      box-shadow: 0px 0px 5px 0px rgba(2, 6, 32, 0.5);

      .close {
        height: 15px;
        width: 15px;

        svg {
          fill: $madero-gray;
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  .multi-actions-panel {
    background-color: $lighter-gray;

    .close {
      width: auto;

      svg {
        fill: #999;
      }

      &:hover, &:focus {
        opacity: 1;
      }
    }
  }

  .multi-actions-progress-panel {
    @extend .mx-auto;

    max-width: 583px;
  }

  label {
    &.btn {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      margin-bottom: 0;
    }
  }
}

.search-buttons {
  display: flex;

  >* {
    flex: 1 1 auto;
    margin-left: 1rem;
  }

  @media screen and (max-width: $screen-sm) {
    >* {
      margin-top: 1rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: $screen-xs) {
    flex-direction: column;

    >* {
      width: 100%;
      margin-left: 0;
    }
  }
}

.form-control.readonly-clean[readonly] {
  background-color: $white;
}

//Show Focus
.show-focus {
  &:hover,
  &:focus {
    background: transparentize($rise-blue,.9);
  }
}