.action-sheet {
  width: 100%;
  height: auto;
  max-height: 75%;
  background-color: #FFF;
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: $zindex-off-canvas;
  padding: 0;

  @include box-shadow(0 0 8px rgba(0, 0, 0, 0.2));

  -webkit-transform: translate3d(0, 100%, 0);
     -moz-transform: translate3d(0, 100%, 0);
      -ms-transform: translate3d(0, 100%, 0);
       -o-transform: translate3d(0, 100%, 0);
          transform: translate3d(0, 100%, 0);
  -webkit-transition: all 0.3s cubic-bezier(0.16, 0.63, 0.45, 0.98);
     -moz-transition: all 0.3s cubic-bezier(0.16, 0.63, 0.45, 0.98);
      -ms-transition: all 0.3s cubic-bezier(0.16, 0.63, 0.45, 0.98);
       -o-transition: all 0.3s cubic-bezier(0.16, 0.63, 0.45, 0.98);
          transition: all 0.3s cubic-bezier(0.16, 0.63, 0.45, 0.98);

  &.is-action-sheet-opened {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

  ul {
    padding: 0;
    margin: 0;
  }

  &.is-action-sheet-closed {
    display: none;
  }

  .menu-box {
    width: 50%;
  }
  li {
    list-style: none;
    display: list-item;
    margin: 0;
    > a {
      padding: 8px;
    }
    &:active { background-color: $gray-lighter; }
    a.action {
      display: block;
      height: 40px !important;
      line-height: 40px !important;
      color: $text-color !important;
    }
  }
}

li.dropdown-footer {
  padding: 0 8px 0;
}

li.dropdown-header {
  padding: 8px;
}

#action-sheet-backdrop.modal-backdrop {
  display: none;
  background-color: #000;
  opacity: 0.5;

  &.is-action-sheet-opened {
    display: block;
  }
}
