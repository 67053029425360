@media (min-width: $screen-sm) {
  .calendar-component-container {
    --calendar-component-body-height: calc(100vh - var(--body-height) - var(--component-header-height));
    --calendar-component-action-button-bar-height: 75px;
  }
}

@media (max-width: $screen-sm) {
  .calendar-component-container {
    --calendar-component-body-height: calc(100vh - var(--body-height));
    --calendar-component-action-button-bar-height: 65px;
  }
}

.calendar-component-settings {
  height: calc(var(--calendar-component-body-height) - var(--calendar-component-action-button-bar-height));
}

.calendar-component-action-bar {
  height: var(--calendar-component-action-button-bar-height);
  padding: 0px 20px;
  background: $white;
}

.calendar-horizontal-line {
  display: flex;
  align-items: center;
  text-align: center;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $madero-gray;
  }
}

.calendar-or {
  padding: 20px 10px;
  font-weight: 700;
  font-size: 12px;
  color: $madero-gray;
}
